import { errorCallback, successCallback } from '@/helpers/validation';
import Touch from './touch';

Touch.prototype.connectGetConnectSubscriptions = async function connectGetConnectSubscriptions() {
  return this.parseResponse(await this.authenticatedPost('connect/GetConnectSubscriptions'));
};

Touch.prototype.connectGetConnectSources = async function connectGetConnectSources() {
  return this.parseResponse(await this.authenticatedPost('connect/GetConnectSources'));
};

Touch.prototype.connectUpdateConnectSource = async function connectUpdateConnectSource(
  id,
  type,
  datasetId,
) {
  return this.parseResponse(
    await this.authenticatedPost('connect/UpdateConnectSource', {
      Id: id,
      Type: type,
      DatasetId: datasetId,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.connectUpdateConnectCustomerProductLinks =
  async function connectUpdateConnectCustomerProductLinks(id, link) {
    return this.parseResponse(
      await this.authenticatedPost('connect/UpdateConnectCustomerProductLinks', {
        DatasetId: id,
        ProductLink: link,
      }),
    );
  };

Touch.prototype.getConnectCodes = async function getConnectCodes() {
  return this.parseResponse(await this.authenticatedPost('connect/GetConnectCodes'));
};

Touch.prototype.createStockSubscription = async function createStockSubscription(
  Username,
  Password,
  Code,
) {
  return this.parseResponse(
    await this.authenticatedPost('connect/CreateStockSubscription', {
      Username,
      Password,
      Code,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.CreateConnectSubscription = async function CreateConnectSubscription(
  Username,
  Password,
  Code,
  ConnectSourceType
) {
  return this.parseResponse(
    await this.authenticatedPost('connect/CreateConnectSubscription', {
      Username,
      Password,
      Code,
      ConnectSourceType
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};
