<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Pricing'],
        ]"
      />
    </template>
    <template #header_page_title> Pricing </template>
    <portal to="portal_search">
      <SearchBox class="flex" placeholder="Search for an item to edit a price..." />
    </portal>
    <template #header_page_actions>
      <div class="ml-auto flex flex-col justify-around">
        <label class="px-3 md:px-10 flex items-center">
          <div class="flex border items-center rounded">
            <div class="p-1 mx-1"><span class="hidden xs:inline">Show </span>FOC<span class="hidden xs:inline"> items only</span></div>
            <div class="p-1 mx-1">
              <input v-model="focFilterActive" type="checkbox" class="w-4 h-4 mt-1" />
            </div>
          </div>
        </label>
      </div>
    </template>
    <Menu></Menu>
    <loading v-if="loading" :loading="true" class="bg-gray-200 h-full" style="flex: 1 1 0"></loading>
    <div v-else class="flex flex-col flex-grow">
      <div
        class="flex flex-col md:flex-row gap-5 p-5 flex-grow bg-gray-200 w-full overflow-y-auto md:overflow-y-hidden"
        style="flex: 1 1 0"
        @scroll.passive="scrollAnomalies"
      >
        <div class="flex flex-col gap-2 flex-shrink-0">
          <div class="border border-gray-400 bg-white p-5">
            <div
              class="p-3 sm:border-none flex justify-between sm:pointer-events-none"
            >
              <h1 class="text-center text-base">Pricing Groups</h1>
            </div>
          </div>
          <div
            class="border border-gray-400 bg-white p-5 overflow-y-auto p-3 sm:block"
            style="flex: 1 1 0; min-height: 200px;"
          >
            <ul>
              <div
                v-for="group in groupsForDisplay"
                :key="group.id"
              >
                <li
                  :class="{ 'bg-green-600 text-white': active_section === group.id }"
                  class="mt-1 flex justify-between bg-gray-200 rounded-lg p-2 cursor-pointer group hover:bg-green-600 hover:text-white text-xs sm:text-sm"
                  @click="selectGroup(group.id)"
                >
                  <div class="pr-6">{{ group.description }}</div>
                  <div v-if="group.prices" class="flex">
                    <div class="text-xs">({{ group.prices.length }})</div>
                    <div class="flex flex-col justify-around pl-2">
                      <i
                        class="fa fa-caret-right opacity-0 group-hover:opacity-100"
                        :class="{ 'opacity-100': active_section === group.id }"
                      ></i>
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div class="flex flex-col w-full">
          <loading v-if="isLoadingPriceData" :loading="true" class="border border-gray-400 bg-white p-5 h-full" style="flex: 1 1 0"></loading>
          <div
            v-else
            class="md:overflow-y-auto h-full border border-gray-400  bg-white w-full"
            style="flex: 1 1 0;"
            @scroll.passive="scrollAnomalies"
          >
            <div
              v-if="anomalies.length === 0 && $route.query.search"
              class="flex justify-between bg-white"
            >
              <div class="flex flex-col justify-around">
                <div class="p-3">No Matching Products Found</div>
              </div>
              <div class="p-3">
                <button class="btn bg-white" @click="$router.replace({ query: undefined })">
                  clear search
                </button>
              </div>
            </div>
            <div v-else-if="anomalies.length === 0" class="flex justify-between bg-white">
              <div class="flex flex-col justify-around">
                <div class="p-3">No Products Found</div>
              </div>
            </div>
            <table class="table-pricing mb-6">
              <tr
                v-for="price in anomaliesForDisplay"
                :key="price.id"
              >
                <td>
                  <price-line
                    :customer-id="customer.customerId"
                    :dataset-id="customer.datasetIds[0]"
                    :default-currency="defaultCurrency"
                    :price="price"
                    :list-value="price.listValue"
                    :is-price-selectable="false"
                    @price-updated="priceUpdated(price, $event)"
                  >
                  </price-line>
                </td>
              </tr>
              <tr> 
                <loading v-if="isLazyLoading" :loading="true" class=""></loading>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pending_section">
      <modal-window
        :modal_open="pending_section"
        :max-width="800"
        @close="(isEdited = false), (pending_section = undefined)"
      >
        <div>There are unsaved changes in this Pricing Group.</div>
        <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
          <button
            class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
            @click.prevent="pending_section = undefined"
          >
            Go Back
          </button>
          <button
            class="btn-action btn-lg"
            @click="(isEdited = false), selectGroup(pending_section)"
          >
            Ignore & Continue
          </button>
        </div>
      </modal-window>
    </div>
    <div
      v-if="!loading"
      class="text-right w-full p-3 bg-white border-t"
      :class="{ 'pointer-events-none opacity-50': JSON.stringify(anomalies) === JSON.stringify(original_anomalies) || isLoadingPriceData }"
    >
      <button class="btn-action btn-lg" @click="saveSettings">Save Changes</button>
    </div>
  </touch-layout>
</template>

<script>
import { mapActions } from 'vuex';
import validate from 'validate.js';
import PriceLine from '@/components/shared/PriceLine.vue';
import Menu from '@/components/business/customer/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    'price-line': PriceLine,
    Menu,
    Breadcrumbs,
    SearchBox,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      loading: true,
      isLoadingPriceData: true,
      defaultCurrency: undefined,
      focFilterActive: false,
      groups: [],
      anomalies: [],
      original_anomalies: [],
      active_section: null,
      isEdited: false,
      pending_section: undefined,
      offset: 0,
      limit: 50,
      noMoreResults: false,
      isLazyLoading: false
    };
  },
  computed: {
    groupsForDisplay() {
      if (this.$route.query.search) {
        return this.groups.filter((x) => this.anomalies.find((y) => y.groupId === x.id));
      }
      return this.groups;
    },
    anomaliesForDisplay() {
      if (this.focFilterActive) {
        return this.anomalies.filter((x) => x.overrideValue === 0 || x.overrideValue === '0');
      }
      return this.anomalies.filter((x) => this.active_section ? x.groupId === this.active_section : true);
    },
  },
  watch: {
    '$route.query': {
      async handler(query) {
        if (query.search) {
          this.offset = 0;
          this.limit = 50;
          this.active_section = undefined
          await this.getAnomalies(undefined, query.search);
        } else if (this.groups[0]) {
          this.active_section = this.groups[0].id;
          await this.getAnomalies(this.active_section);
        }
      },
    },
  },
  created() {
    this.$router.replace({ query: undefined }).catch(() => { });
  },
  async mounted() {
    this.loading = true;
    this.defaultCurrency = (await this.$store.dispatch('touch/commonGetCurrencies')).filter((currency) => currency.isDefault)[0]?.code;
    await this.loadSettings();
    this.loading = false;
    this.isLoadingPriceData = false
  },
  methods: {
    async scrollAnomalies({ target: { scrollTop, clientHeight, scrollHeight } }) {
      const offset = 10
      if (!this.$route.query.search && scrollTop + clientHeight + offset >= scrollHeight && !this.isLazyLoading && !this.noMoreResults) {
        this.offset += 50;
        this.isLazyLoading = true
        await this.getAnomalies(this.active_section);
        this.isLazyLoading = false
      }
    },
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    modifiedAnomalies() {
      return this.anomalies.filter(
        (anomaly) =>
          this.original_anomalies.findIndex(
            (originalAnomaly) =>
              originalAnomaly.id === anomaly.id &&
              (originalAnomaly.overrideValue !== anomaly.overrideValue || originalAnomaly.valueIsNett !== anomaly.valueIsNett),
          ) !== -1,
      );
    },
    priceUpdated(price, object) {
      this.isEdited = true;
      const modifiedPrice = this.anomalies.find((anomaly) => anomaly.id === price.id);
      modifiedPrice.valueIsNett = object.valueIsNett;
      modifiedPrice.overrideValue = object.overrideValue;
    },
    async updateAnomalies() {
      await this.$store.dispatch('touch/staffUpdateAlternativePriceData', {
        Anomalies: this.modifiedAnomalies().map((anomaly) => ({
          Id: anomaly.id,
          Value: anomaly.overrideValue ? Number(anomaly.overrideValue) : 0,
          FOC: !anomaly.valueIsNett && anomaly.overrideValue == 0,
          ValueIsNett: anomaly.valueIsNett
        })),
        CustomerId: this.customer.customerId,
        DatasetId: this.customer.datasetIds[0],
      });
      this.original_anomalies = JSON.parse(JSON.stringify(this.anomalies));
    },
    async getGroups() {
      this.groups = (
        await this.$store.dispatch('touch/staffGetFinancialGroups', {
          CustomerId: this.customer.customerId,
          DatasetId: this.customer.datasetIds[0],
        })
      ).data;
      this.groups.push({
        id: '00000000-0000-0000-0000-000000000000',
        description: 'Undefined',
      });
    },
    async getAnomalies(groupId, query) {
      const anomalies = (
        await this.$store.dispatch('touch/staffGetAlternativePriceData', {
          CustomerId: this.customer.customerId,
          DatasetId: this.customer.datasetIds[0],
          GroupId: groupId,
          Description: query,
          Offset: this.offset,
          Limit: this.limit,
        })
      ).data.anomalies
        .map((anomoly) => ({
          ...anomoly,
          overrideValue: anomoly.foc ? 0 : anomoly.overrideValue ? anomoly.overrideValue : null,
          groupText: this.groups.find((group) => group.id === anomoly.groupId).description,
        }));

      if (anomalies.length % 50 !== 0) {
        this.noMoreResults = true;
      }
      if (this.offset === 0) {
        this.original_anomalies = JSON.parse(JSON.stringify(anomalies));
        this.anomalies = anomalies;
      } else {
        this.original_anomalies = JSON.parse(JSON.stringify(this.anomalies.concat(anomalies)));
        this.anomalies = this.anomalies.concat(anomalies);
      }
    },
    async saveSettings() {
      this.isLoadingPriceData = true;
      if (this.validateInput()) {
        await this.updateAnomalies(this.modifiedAnomalies());
        this.isEdited = false;
        this.pending_section = undefined;
      } else {
        this.alertBox().fire({
          title: 'Invalid Input',
          icon: 'error',
        });
      }
      this.isLoadingPriceData = false;
    },
    async loadSettings() {
      await this.getGroups();
      if (this.groups[0]) {
        this.active_section = this.groups[0].id;
        await this.getAnomalies(this.active_section, this.$route.query.search);
      }
    },
    validateInput() {
      let isValid = true;
      this.anomalies.forEach((anomaly) => {
        if (!validate.isNumber(Number(anomaly.overrideValue))) {
          isValid = false;
        }
      });
      return isValid;
    },
    async selectGroup(groupId) {
      this.isLoadingPriceData = true;
      if (this.isEdited) {
        this.pending_section = groupId;
      } else {
        this.active_section = groupId;
        if (!this.$route.query.search) {
          await this.getAnomalies(this.active_section, this.$route.query.search);
        } else {
          this.anomalies = JSON.parse(JSON.stringify(this.original_anomalies));
        }
        this.pending_section = undefined;
        this.noMoreResults = false;
        this.offset = 0;
        this.limit = 50;
        this.isEdited = false;
      }
      this.isLoadingPriceData = false;
    },
  },
};
</script>
